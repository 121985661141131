<template>
  <div class="resource-components-wrapper">
    <div>
      <div class="section section-one">
        <div>
          <div>
            {{ $t('resourceView.one') }}
          </div>
          <div>
            <p>{{ $t('resourceView.two') }}</p>
            <p>{{ $t('resourceView.three') }}</p>
            <div></div>
          </div>
        </div>
      </div>
      <div class="section section-two">
        <img src="@/assets/image/resource/s2.jpg" alt="">
        <div>{{ $t('resourceView.four') }}</div>
        <div>{{ $t('resourceView.five') }}</div>
        <div>{{ $t('resourceView.six') }}</div>
        <div>
          {{ $t('resourceView.seven') }}
          <a href="https://www.nobelprize.org/prizes/medicine/2017/press-release/" target="_bank">https://www.nobelprize.org/prizes/medicine/2017/press-release/</a>
          )
        </div>
      </div>
      <div class="section section-two">
        <img src="@/assets/image/resource/s3.jpg" alt="">
        <div>{{ $t('resourceView.eighth') }}</div>
        <div>{{ $t('resourceView.nine') }}</div>
        <div>{{ $t('resourceView.ten') }}</div>
      </div>
      <div class="section section-two">
        <img src="@/assets/image/resource/s4.jpg" alt="">
        <span>
          <p>
            {{ $t('resourceView.eleven') }}
            <a href="http://www.ox.ac.uk/research/research/research-in-conversation/healthy-body-healthy-mind/russell-foste" target="_bank">http://www.ox.ac.uk/research/research/research-in-conversation/healthy-body-healthy-mind/russell-foste</a>
            )
          </p>
        </span>
      </div>
      <div class="section section-two">
        <img src="@/assets/image/resource/s5.jpg" alt="">
        <div> {{ $t('resourceView.twelve') }}</div>
        <div>
        <p>
          {{ $t('resourceView.thirteen') }}
        </p>
        <p>
          {{ $t('resourceView.fourteen') }}
        </p>
        <p>
          {{ $t('resourceView.fifteen') }}
        </p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.resource-components-wrapper {
  width: 100%;
  background: #F5F6F7;
  padding: 20px;
  & > div {
    .section {
      width: 100%;
      font-size: 13px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      background: #FFFFFF;
      border-radius: 12px;
      overflow: hidden;
      padding: 16px;
      margin-bottom: 12px;
    }
    .section-one {
      background: #fff url('~@/assets/image/resource/s1.jpg') no-repeat;
      background-size: 100% auto;
      padding: 0;
      & > div {
        border-radius: 12px 12px;
        padding: 20px;
        margin-top: 120px;
        background: #fff;
        overflow: hidden;
        &>div {
          &:nth-child(1) {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
            margin-bottom: 16px;
          }
        }
      }
    }
    .section-two {
      overflow: hidden;
      & > img {
        width: 100%;
        margin-bottom: 16px;
      }
      & > div:nth-child(2) {
        height: 24px;
        font-size: 14px;
        font-weight: bold;
        color: #222222;
        line-height: 24px;
        margin-bottom: 8px;
      }
      & > div, & > span {
        width: 100%;
        overflow: hidden;
        word-wrap:break-word;
        display: block;
        a {
          color: rgba(15, 147, 202, 1);
        }
      }
    }
  }
  // .section-wrapper {
  //   width: 1200px;
  //   margin: 0 auto;
  //   overflow: hidden;
  //   background: #fff;
  //   padding-bottom: 236px;
  //   .section-one {
  //     width: 100%;
  //     height: 530px;
  //     background: url('~@/assets/image/resource/s1.jpg') no-repeat center center;
  //     display: flex;
  //     align-items: center;
  //     &>div {
  //       min-width: 476px;
  //       background: #fff;
  //       margin-left: 77px;
  //       padding: 50px;
  //       &>div:nth-child(1) {
  //         width: 384px;
  //         color: #333333;
  //         line-height: 36px;
  //         font-size: 24px;
  //         font-weight: bold;
  //         text-align: justify;
  //       }
  //       &>div:nth-child(2) {
  //         font-size: 18px;
  //         line-height: 25px;
  //         color: #333333;
  //         margin-top: 30px;
  //         &>div {
  //           width: 94px;
  //           height: 1px;
  //           margin-top: 70px;
  //           background: #000;
  //         }
  //       }
  //     }
  //   }
  //   .section-two {
  //     margin-top: 72px;
  //     width: 100%;
  //     &>div {
  //       &.section-two-section {
  //         display: flex;
  //         justify-content: space-between;
  //         height: 422px;
  //         width: 100%;
  //         align-items: center;
  //         &>div {
  //           &:nth-child(1) {
  //             width: 680px;
  //           }
  //           &:nth-child(2) {
  //             width: 435px;
  //             & > div {
  //               &:nth-child(1) {
  //                 font-size: 27px;
  //                 font-weight: bold;
  //                 color: #222222;
  //                 margin-bottom: 22px;
  //               }
  //               font-size: 18px;
  //               color: #333333;
  //               line-height: 32px;
  //             }
  //           }
  //           &.right {
  //             order: 2;
  //           }
  //           &.left {
  //             order: 1;
  //           }
  //         }
  //       }
  //       &:nth-child(2) {
  //         font-size: 18px;
  //         color: #333333;
  //         line-height: 32px;
  //         margin-top: 60px;
  //         a {
  //           color: rgba(17, 145, 218, 1);
  //         }
  //       }
  //       &:nth-child(3) {
  //         margin-top: 100px;
  //       }
  //       &:nth-child(4) {
  //         margin-top: 100px;
  //         p {
  //           font-size: 18px;
  //           font-weight: 400;
  //           color: #333333;
  //           line-height: 32px;
  //           margin-bottom: 0;
  //           a {
  //             color: rgba(17, 145, 218, 1);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // .section-footer {
  //   width: 100%;
  //   background: #F5F6F7;
  //   min-height: 820px;
  //   &>div {
  //     &:nth-child(1) {
  //       width: 877px;
  //       height: 366px;
  //       margin: 0 auto;
  //       img {
  //         width: 100%;
  //         margin-top: -84px;
  //       }
  //     }
  //     &:nth-child(2) {
  //       width: 100%;
  //       text-align: center;
  //       font-size: 42px;
  //       font-weight: 600;
  //       color: #222222;
  //       line-height: 54px;
  //       margin-top: -15px;
  //     }
  //     &:nth-child(3) {
  //       padding-top: 40px;
  //       padding-bottom: 124px;
  //       max-width: 976px;
  //       margin: 0 auto;
  //       & > p {
  //         text-align: center;
  //         font-size: 18px;
  //         color: #333333;
  //         line-height: 32px;
  //         &:nth-child(2) {
  //           margin-top: 30px;
  //         }
  //       }
  //     }
  //   }
  // }
}
</style>